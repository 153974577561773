import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Selskap i konsernet',
    paragraph: 'Gjennom fem selskap dekker Alt Legalt bredden i juridisk tjenesteyting. Kvalitet, effektivitet og digital innovasjon er nøkkelord. Kvalitet leveres fra stort fagmiljø med spesialiserte advokater. Effektivitet skjer gjennom drift og innovasjon på tvers av selskapene i konsernet. Kontinuerlig digital innovasjon sikres gjennom eget IT-selskap.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Advokatfirma
                  </div>
                <h3 className="mt-0 mb-12">
                  Legalis
                  </h3>
                <p className="m-0">
                  Advokatfirmaet Legalis bistår privatpersoner og bedrifter over hele landet innen eiendom, avtalerett, arbeidsrett og de fleste andre rettsområder. Med en kombinasjon av spesialisert kompetanse og fornuftig pris har Legalis vokst kraftig senere år, og består nå av over 30 advokater. <a href="https://legalis.no" target="_blank">Les mer</a></p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/legalis.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Advokatfirma
                  </div>
                <h3 className="mt-0 mb-12">
                  Legal24
                  </h3>
                <p className="m-0">
                  Legal24 Advokatfirma er blant landets fremste selskap på bistand under rettshjelpsforsikring, herunder bedriftsforsiking, boligkjøperforsikring og advokatforsikring med vekt på familie- og arverett. Høyt faglig nivå sikrer gode resultater, og målet er å levere kvalitet i alle ledd fra saken mottas. <a href="https://legal24.no" target="_blank">Les mer</a>
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/legal24.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Advokatfirma
                  </div>
                <h3 className="mt-0 mb-12">
                  Legal24 AB
                  </h3>
                <p className="m-0">
                  Legal24 AB er svensk søsterselskap, med spesialisert bistand under rettshjelpsforsikring på det svenske markedet. Selskapet leverer advokattjenester til en rekke offentlige og private aktører med dekning under forsikring. <a>Nettside kommer snart</a></p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/legal24-sverige.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Drift og kundeservice
                  </div>
                <h3 className="mt-0 mb-12">
                  Legal Services
                  </h3>
                <p className="m-0">
                Legal Services AS leverer komplette tjenester innen drift og kundeservice til advokatbransjen, samt juridisk saksbehandling knyttet til blant annet ID-tyveri. Selskapet leverer slike tjenester til Alt Legalt-konsernet og til andre firma med slike behov. <a>Nettside kommer snart</a></p>              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/ls.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Teknologiselskap
                  </div>
                <h3 className="mt-0 mb-12">
                  Legalit
                  </h3>
                <p className="m-0">
                  Legalit AS leverer digital innovasjon til konsernet og til eksterne. Selskapet har blant annet utviklet advokatchatten.no og legalis.no, og arbeider kontinuerlig med teknologisk utvikling som sørger for at Alt Legalt er der vi skal være: Alltid i front. Juridisk og digitalt. <a href="https://legalit.no" target="_blank">Les mer</a>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/legalit.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;