import React, { useEffect, useRef } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {

  const myRef = useRef();
  const contactRef = useRef();

  function onAction(target) {
    if(target === "companies") {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }else if(target === "contact") {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });

    }
  }

  return (
    <>
      <Hero className="illustration-section-01" onAction={onAction} />
      <div ref={myRef} />
      
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <div ref={contactRef} />
      <Cta />
    </>
  );
}

export default Home;